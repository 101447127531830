import { ElNotification } from "element-plus";
import store from "../store";
import { tagsConvertor } from "../helpers/tagsConvertor";
import api from "./api";

export const createPost = async (
  postType,
  postData,
  customTaxs,
  collectorItems
) => {
  let data = store.state.customFields[postType];
  if (postData && data) {
    if (postType === "artists") {
      let formdata = new FormData();
      formdata.append("name", postData.title);
      formdata.append("slug", postData.slug);
      postData.featuredImage !== 0 &&
        formdata.append("image_id", postData.featuredImage);
      data.short_description &&
        formdata.append("short_description", data.short_description);
      formdata.append("about", postData.content);
      data.born && formdata.append("born", data.born);
      data.died && formdata.append("died", data.died);
      formdata.append("biography", data.biography);
      formdata.append("awards", JSON.stringify(data.awards));
      data.valueIndicator.name &&
        formdata.append("value_indicator[name]", data.valueIndicator.name);
      data.valueIndicator.iconUrl &&
        formdata.append(
          "value_indicator[icon_url]",
          data.valueIndicator.iconUrl
        );
      data.valueIndicator.valueIndicators !== "" &&
        formdata.append(
          "value_indicator[content][]",
          data.valueIndicator.valueIndicators
        );
      formdata.append("exhibitions", JSON.stringify(data.exhibitions));
      formdata.append(
        "status_id",
        postData.status == "publish" ? 2 : postData.status
      );
      const artist = await api.post(
        `${process.env.VUE_APP_SHARED_API}api/${postType}`,
        formdata
      );
      return artist;
    }
    if (postType === "artworks") {
      let formdata = new FormData();
      formdata.append("title", postData.title);
      formdata.append("slug", postData.slug);
      formdata.append("artist_id", data.artistId);
      formdata.append("price", data.price);
      formdata.append("size_width", data.sizeWidth);
      formdata.append("size_height", data.sizeHeight);
      formdata.append("year", data.year);
      formdata.append("year_end", data.year_end);
      formdata.append("quantity", data.quantity);
      formdata.append("meta_title", postData.meta_title);
      formdata.append("suites_featured_image_id", postData.suites_featured_image_id);
      formdata.append("meta_description", postData.meta_description);
      formdata.append("out_of_stock", "0");
      formdata.append("has_border", postData.has_border  ? "1" : "0");
      postData.featuredImage !== 0 &&
        formdata.append("featured_image_id", postData.featuredImage);
      postData.featuredImage !== 0 &&
        formdata.append("image_id", postData.featuredImage);
      formdata.append("status", postData.status == "publish" ? 1 : 0);
      data.collectorTrendsTitle &&
        formdata.append("collector_trends_title", data.collectorTrendsTitle);

      data.gallery.map((galleryId) => {
        formdata.append("gallery[]", galleryId.id);
      });
      formdata.append("value_indicators", JSON.stringify(data.valueIndicators));
      formdata.append("overview", postData.content);
      data.techniques.map((techniques) => {
        formdata.append("technique_ids[]", techniques.techniqueId);
      });
      collectorItems &&
        collectorItems.map((item) => {
          formdata.append("collector_items[]", item);
        });

      function replaceWithUnderscore(name) {
        return name.replace(/-/g, "_");
      }

      customTaxs.map((tax) => {
        tax.taxs.map((t) => {
          formdata.append(`${replaceWithUnderscore(tax.name)}[]`, t);
        });
      });
      let tags = await tagsConvertor(
        postData.choosenTags,
        postData.primaryTagId
      );
      if (tags.length > 0) {
        formdata.append("tags", JSON.stringify(tags));
      }
      const artwork = await api.post(
        `${process.env.VUE_APP_SHARED_API}api/${postType}`,
        formdata
      );
      return artwork;
    }
    if (postType === "techniques") {
      let formdata = new FormData();
      formdata.append("name", postData.title);
      formdata.append("slug", postData.slug);
      formdata.append(
        "featured_image_id",
        postData.featuredImage ? postData.featuredImage : ""
      );
      formdata.append("text", postData.content);
      formdata.append("value_indicator[name]", data.valueIndicator.name);
      formdata.append("value_indicator[icon_url]", data.valueIndicator.iconUrl);
      data.valueIndicator.valueIndicators !== "" &&
        formdata.append(
          "value_indicator[content][]",
          data.valueIndicator.valueIndicators
        );
      formdata.append("principles", data.principles);
      let artistsIds = [];
      data.artist_ids.map((item) => {
        artistsIds.push(item.artistId);
      });
      formdata.append(
        "artist_ids",
        artistsIds.toString().replace("[", "").replace("]", "")
      );
      postData.pdfId > 0 && formdata.append("pdf_id", postData.pdfId);
      const technique = await api.post(
        `${process.env.VUE_APP_SHARED_API}api/${postType}`,
        formdata
      );
      return technique;
    }
  } else {
    ElNotification({
      title: "Oops!",
      message: "Something went wrong, please try later",
      type: "warning",
    });
  }
};
